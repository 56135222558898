import React from "react";

const Footer = () => {
    return (
        <footer className={"footer container-fluid text-center py-4 align-items-center justify-content-center"}>
            <p className={"mb-0"}>2024 - Gašper Pintar</p>
            <a
                href={"/files/document/termsOfService.html"}
                target={"_blank"}
                rel={"noreferrer"}
                className={"m-1"}>
                Terms of Service
            </a>
            <a
                href={"/files/document/privacyPolicy.html"}
                target={"_blank"}
                rel={"noreferrer"}
                className={"m-1"}>
                Privacy Policy
            </a>
        </footer>
    )
}

export default Footer