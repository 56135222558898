import { useAuth } from "../../../auth/AuthContext";
import {useParams} from "react-router-dom";
import Page404 from "../../../pages/404";
import React, { useState, useEffect } from "react";
import * as MissionsHandler from "./MissionsHandler";
import * as Cookies from "../../../auth/cookies/Cookies"

const Missions = () => {

    const [missionsStatistics, setMissionStatistics] = useState([]);
    const [selectedMission, setSelectedMission] = useState(null);
    const [loading, setLoading] = useState(true);

    const { userData } = useAuth();
    const { username, name } = useParams();

    useEffect(() => {
        if (name) {
            MissionsHandler.getMissionStatistics(name, Cookies.getToken()).then(result => {
                if (result.success) {
                    setMissionStatistics(result.data === undefined ? [] : result.data);

                    if (result.data !== undefined && result.data.length > 0) {
                        setSelectedMission(result.data[0]);
                        setLoading(false);
                    }

                    if (result.data !== undefined && result.data.length > 0) {
                        setLoading(false);
                    }
                    return;
                }
                setMissionStatistics([]);
                setLoading(false);
            });
        }
    }, [name]);

    if (loading) {
        return null;
    }

    if (userData.username !== username) {
        return <Page404 />;
    }

    const handleRowClick = (weapon) => {
        setSelectedMission(weapon);
    };

    const getRowClassName = (mission) => {
        return selectedMission && selectedMission.id === mission.id ? "table-active" : "";
    };

    return (
        <div className={"statistics container mt-5"}>
            <div className={"row mt-5"}>
                <div className={"col-lg-8 col-12 order-lg-1 order-2"}>
                    <div className={"table-responsive"}>
                        <table className={"table table-bordered table-light table-hover"}>
                            <thead className={"thead-dark"}>
                            <tr>
                                <th className={"text-center"}>#</th>
                                <th className={"text-center"}>Score</th>
                                <th className={"text-center"}>Money</th>
                                <th className={"text-center"}>Used time</th>
                                <th className={"text-center"}>Played</th>
                                <th className={"text-center"}>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {missionsStatistics.map((mission, index) => (
                                <tr
                                    key={mission.id}
                                    onClick={() => handleRowClick(mission)}
                                    className={`${getRowClassName(mission)}`}
                                >
                                    <td className={"text-center"}>{index + 1}</td>
                                    <td className={"text-center"}>{mission.score}</td>
                                    <td className={"text-center"}>{JSON.parse(mission.money).total}€</td>
                                    <td className={"text-center"}>{mission.usedTime}</td>
                                    <td className={"text-center"}>{mission.playedOn}</td>
                                    <td className={`text-center ${mission.status === "Win" ? "win" : "lost"}`}>
                                        {mission.status}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <aside className={"col-lg-4 col-sm-12 order-lg-2 order-1 mb-sm-2 aside-fixed"}>
                    <div className={"card"}>
                        {selectedMission ? (
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"d-flex justify-content-between"}>
                                        {selectedMission.idUser !== null && selectedMission.playedCount !== 0 ?
                                            <h5 className={"card-title mt-2"}>{name}</h5>
                                            : selectedMission.name}
                                    </div>
                                    <div className={"col-lg-12 col-sm-12 text-center mb-2"}>
                                        <img
                                            src={`${process.env.REACT_APP_MISSION_IMAGES}${selectedMission.image}`}
                                            className={"img-fluid weapon-selected"}
                                            alt={selectedMission.name}
                                        />
                                    </div>
                                    <hr/>

                                    <div className={"statistics-single"}>
                                    <h5 className={"card-title text-center"}>Mission statistics</h5>
                                    <div className={"statistics-outer"}>
                                        <div className={"col-lg-12 col-sm-4 statistics"}>
                                            <p className={"card-text m-0"}>Score: {selectedMission.score}</p>
                                            <ul className="card-text m-0">
                                                {Object.entries(JSON.parse(selectedMission.money))
                                                    .filter(([key, value]) => value > 0).length > 0 && (
                                                    <li>Money
                                                        <ul>
                                                            {Object.entries(JSON.parse(selectedMission.money))
                                                                .filter(([key, value]) => value > 0)
                                                                .map(([key, value]) => (
                                                                    <li key={key}>
                                                                        {key.replace(/([A-Z])/g, " $1").toLowerCase()}: {value}€
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </li>
                                                )}
                                            </ul>
                                            <p className={"card-text m-0"}>Game status: {selectedMission.status}</p>
                                            <p className={"card-text m-0"}>Used Time: {selectedMission.usedTime}</p>
                                            <p className={"card-text m-0"}>Distance
                                                Traveled: {selectedMission.distanceTraveled}%</p>
                                            <p className={"card-text m-0"}>Shots Fired: {selectedMission.shotsFired}</p>
                                            <p className={"card-text m-0"}>Missed
                                                Shots: {selectedMission.missedShots}</p>
                                            <p className={"card-text m-0"}>Accuracy: {selectedMission.accuracy}%</p>
                                            <p className={"card-text m-0"}>Critical
                                                Hits: {selectedMission.criticalHits}</p>
                                            <p className={"card-text m-0"}>Headshots: {selectedMission.headshots}</p>
                                            <p className={"card-text m-0"}>Enemies
                                                Alerted: {selectedMission.enemiesAlerted}</p>

                                            <ul className="card-text m-0">
                                                {Object.entries(JSON.parse(selectedMission.enemyTypesKilled))
                                                    .filter(([key, value]) => value > 0).length > 0 && (
                                                    <li>Enemy Types Killed
                                                        <ul>
                                                            {Object.entries(JSON.parse(selectedMission.enemyTypesKilled))
                                                                .filter(([key, value]) => value > 0)
                                                                .map(([key, value]) => (
                                                                    <li key={key}>
                                                                        {value} {key}{value !== 1 ? "s" : ""}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </li>
                                                )}
                                                {Object.entries(JSON.parse(selectedMission.hostageTypesKilled))
                                                    .filter(([key, value]) => value > 0).length > 0 && (
                                                    <li>Hostage Types Killed
                                                        <ul>
                                                            {Object.entries(JSON.parse(selectedMission.hostageTypesKilled))
                                                                .filter(([key, value]) => value > 0)
                                                                .map(([key, value]) => (
                                                                    <li key={key}>
                                                                        {value} {key}{value !== 1 ? "s" : ""}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </li>
                                                )}
                                                {Object.entries(JSON.parse(selectedMission.itemsCollected))
                                                    .filter(([key, value]) => value > 0).length > 0 && (
                                                    <li>Items Collected
                                                        <ul>
                                                            {Object.entries(JSON.parse(selectedMission.itemsCollected))
                                                                .filter(([key, value]) => value > 0)
                                                                .map(([key, value]) => (
                                                                    <li key={key}>
                                                                        {value} {key}{value !== 1 ? "s" : ""}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </li>
                                                )}
                                            </ul>
                                            <p className={"card-text m-0"}>Played On: {selectedMission.playedOn}</p>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            <div className={"text-center justify-content-center"}>Choose a mission for more
                                info</div>
                        )}
                    </div>
                </aside>
            </div>
        </div>
    );
};

export default Missions;